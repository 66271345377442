import {DetailedHTMLProps, forwardRef, IframeHTMLAttributes, Ref} from "react"

type Props = {} & DetailedHTMLProps<
  IframeHTMLAttributes<HTMLIFrameElement>,
  HTMLIFrameElement
>
const FoleonViewer = (props: Props, ref: Ref<HTMLIFrameElement>) => {
  return <iframe {...props} ref={ref} />
}

FoleonViewer.displayName = "FoleonViewer"

export default forwardRef<HTMLIFrameElement, Props>(FoleonViewer)
