import {Box, Stack, StackProps, ThemeProvider, Typography} from "@mui/material"
import {FC} from "react"
import {DottedSpinnerIcon} from "../atoms"
import {theme} from "../../theme"

type Props = {
  containerProps?: StackProps
  loadingMessage?: string
}

const LoadingIndicator: FC<Props> = (props) => {
  const {containerProps, loadingMessage = "Deliverable loading..."} = props

  return (
    <ThemeProvider theme={theme}>
      <Stack
        id="loading-indicator"
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="calc(100vh - 88px)"
        width="calc(100vw - 56px)"
        {...containerProps}
        sx={{
          position: "fixed",
          top: 88,
          right: 0,
          zIndex: 99,
          height: "calc(100vh - 88px)",
          width: "calc(100vw - 56px)",
          ...containerProps?.sx,
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap="16px"
        >
          <Box
            id="loading-spinner"
            height="46px"
            width="46px"
            sx={{
              "@keyframes spin": {
                from: {transform: "rotate(0deg)"},
                to: {transform: "rotate(360deg)"},
              },
              animation: "spin 2s linear infinite",
            }}
          >
            <DottedSpinnerIcon height="46px" width="46px" />
          </Box>
          <Typography
            id="loading-message"
            fontSize="14px"
            fontWeight="600"
            color="#242D35"
            lineHeight="21px"
          >
            {loadingMessage}
          </Typography>
        </Stack>
      </Stack>
    </ThemeProvider>
  )
}

export default LoadingIndicator
